'use client'

import React, { useCallback, useMemo, useState } from 'react'

import emptyFunction from '../utils/emptyFunction'

type Props = {
  isAdmin: boolean
  children?: React.ReactNode
}

export type AdminContext = {
  isAdmin: boolean
  unlockAdmin: (password: string) => Promise<boolean>
}

export const AdminContext = React.createContext<AdminContext>({
  isAdmin: false,
  unlockAdmin: emptyFunction as any,
})

export default function AdminProvider({ isAdmin: isInitialAdmin, children }: Props) {
  const [isAdmin, setIsAdmin] = useState(isInitialAdmin)

  const unlockAdmin = useCallback(async (password: string) => {
    const verifyRes = await fetch('/api/intern/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    })
    if (verifyRes.ok) {
      setIsAdmin(true)
      return true
    } else {
      throw new Error('Admin auth failed')
    }
  }, [])

  const value = useMemo(() => {
    return {
      isAdmin,
      unlockAdmin,
    }
  }, [isAdmin, unlockAdmin])

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}
