import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import useSWR from 'swr'
import { Address } from 'viem'

import tgeClaimAbi from '../abis/tgeClaimAbi'
import { lyraClient } from '../constants/client'
import { lyraContractAddresses } from '../constants/contracts'
import {
  EMPTY_SNAPSHOT_BALANCE,
  ProtocolBalanceBn,
  SnapshotBalanceResponseBn,
} from '../constants/lyraSnapshot'
import useAuth from './useAuth'

type ClaimableMigrationBalance = {
  claimableDrvBalance: bigint
  claimableStDrvBalance: bigint
  snapshotBalances: SnapshotBalanceResponseBn
}

const EMPTY_MIGRATION_BALANCE: ClaimableMigrationBalance = {
  claimableDrvBalance: BigInt(0),
  claimableStDrvBalance: BigInt(0),
  snapshotBalances: EMPTY_SNAPSHOT_BALANCE,
}

const fetcher = async (ownerAddress: Address): Promise<ClaimableMigrationBalance | null> => {
  const [claimableDrvBalance, claimableStDrvBalance, _userSnapshotBalancesRes] = await Promise.all([
    lyraClient.readContract({
      abi: tgeClaimAbi,
      address: lyraContractAddresses.migrationClaim,
      functionName: 'claimableDRV',
      args: [ownerAddress],
    }),
    lyraClient.readContract({
      abi: tgeClaimAbi,
      address: lyraContractAddresses.migrationClaim,
      functionName: 'claimableStDRV',
      args: [ownerAddress],
    }),
    fetch(`/api/drv-balances?address=${ownerAddress}`),
  ])

  const _userSnapshotBalances = await _userSnapshotBalancesRes.json()
  const userBalanceData: SnapshotBalanceResponseBn = {
    totalBalance: toBigNumber(_userSnapshotBalances.totalBalance, WEI_DECIMALS),
    protocolBalances: _userSnapshotBalances.protocolBalances.map((pb: ProtocolBalanceBn) => ({
      protocol: pb.protocol,
      chain: pb.chain,
      balance: toBigNumber(pb.balance, WEI_DECIMALS),
      token: pb.token,
      description: pb.description,
    })),
  }

  return {
    claimableDrvBalance,
    claimableStDrvBalance,
    snapshotBalances: userBalanceData,
  }
}

export default function useMigrationBalances() {
  const { user } = useAuth()
  const address = user?.ownerAddress
  const { data, error, isLoading, mutate } = useSWR(
    ['MigrationBalances', address],
    ([_, ownerAddress]) => (ownerAddress ? fetcher(ownerAddress) : null),
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    }
  )

  return {
    ...(data ?? EMPTY_MIGRATION_BALANCE),
    isLoading,
    isError: !!error,
    mutate,
  }
}
