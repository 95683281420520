export const ETHEREUM_MAINNET_SNAPSHOT_BLOCKNUMBER = 19821623
export const ARBITRUM_SNAPSHOT_BLOCKNUMBER = 208941786
export const OPTIMISM_SNAPSHOT_BLOCKNUMBER = 119763811

// May 7 2024 11:59:59PM
export const MIGRATION_SNAPSHOT_TIMESTAMP_SEC = 1715126400

import { Address, Hash } from 'viem'

export type ProtocolBalance = {
  protocolName: string
  chain: string
  protocol: string
  description: string
  balance: string
  token: string
}

export type UserBalanceResponse = {
  totalBalance: string
  protocolBalances: ProtocolBalance[]
}

export type ProtocolBalanceBn = ProtocolBalance & {
  balance: bigint
}

export type SnapshotBalanceResponseBn = {
  totalBalance: bigint
  protocolBalances: ProtocolBalanceBn[]
}

export const EMPTY_SNAPSHOT_BALANCE: SnapshotBalanceResponseBn = {
  totalBalance: BigInt(0),
  protocolBalances: [],
}

export type PrestakeEvent = {
  ownerAddress: Address
  amount: string
  timestamp: number
  signature: Hash
  // Max prestake to account for potential updates to snapshot balances
  isMaxPrestake?: boolean
}

export type PrestakeEventBn = PrestakeEvent & {
  amount: bigint
}

export type HolderPointsRouteResponse = {
  balance: number
  share: number
  totalPoints: number
}
