'use client'

import { SECONDS_IN_MINUTE } from '@lyra/core/constants/time'
import AuthErrorModal from '@lyra/web/containers/common/AuthErrorModal'
import TermsOfUseModal from '@lyra/web/containers/common/TermsOfUseModal'
import useAdmin from '@lyra/web/hooks/useAdmin'
import { authenticationAdapter } from '@lyra/web/utils/client/authenticationAdapter'
import { EMITTER_EVENTS, eventEmitter } from '@lyra/web/utils/client/eventEmitter'
import { RainbowKitAuthenticationProvider } from '@rainbow-me/rainbowkit'
import spindl from '@spindl-xyz/attribution'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { useCallback, useEffect, useMemo } from 'react'
import useSWR, { KeyedMutator, SWRConfiguration } from 'swr'
import { Address } from 'viem'
import { State, WagmiProvider } from 'wagmi'

import { Auth, NO_AUTH } from '../constants/auth'
import { rainbowkitWagmiConfig } from '../constants/rainbowkit'
import emptyFunction from '../utils/emptyFunction'
import RainbowKitProvider from './RainbowKitProvider'

// Poll auth every 5 mins
const AUTH_POLLING_INTERVAL_MS = SECONDS_IN_MINUTE * 5 * 1000

type Props = {
  initialAuth: Auth
  initialWalletState?: State
  children?: React.ReactNode
}

export type AuthContext = {
  mutate: KeyedMutator<Auth>
  logout: () => Promise<void>
  refresh: () => Promise<void>
  acknowledgeTerms: () => Promise<void>
  updateUsername: (username: string | null) => Promise<void>
  createMockSessionDONOTUSE: (address: Address) => Promise<void>
} & Auth

export const AuthContext = React.createContext<AuthContext>({
  ...NO_AUTH,
  logout: emptyFunction as any,
  refresh: emptyFunction as any,
  mutate: emptyFunction as any,
  acknowledgeTerms: emptyFunction as any,
  updateUsername: emptyFunction as any,
  createMockSessionDONOTUSE: emptyFunction as any,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
    },
  },
})

const fetchAuth = async (): Promise<Auth> => {
  console.debug('refreshing auth')
  const res = await fetch('/api/auth/refresh')

  if (!res.ok) {
    throw new Error('Failed to authenticate wallet')
  }

  return (await res.json()) as Auth
}

export default function AuthProvider({ initialAuth, initialWalletState, children }: Props) {
  const { isAdmin } = useAdmin()

  const {
    data: auth,
    mutate,
    error,
  } = useSWR<Auth, Error, [string], { fallbackData: Auth } & SWRConfiguration>(
    ['Auth'],
    fetchAuth,
    {
      fallbackData: initialAuth,
      keepPreviousData: true,
      refreshInterval: AUTH_POLLING_INTERVAL_MS,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      shouldRetryOnError: false,
    }
  )

  const refresh = useCallback(async () => {
    const res = await fetch('/api/auth/refresh?forceRefresh=true')
    if (!res.ok) {
      throw new Error('Failed to refresh auth')
    }
    const auth = await res.json()
    mutate(auth, { revalidate: false })
  }, [mutate])

  useEffect(() => {
    eventEmitter.on(EMITTER_EVENTS.SIGN_IN, (auth: Auth) => {
      console.debug('SIGN_IN', auth)
      mutate(auth, { revalidate: false })
    })

    eventEmitter.on(EMITTER_EVENTS.SIGN_OUT, () => {
      console.debug('SIGN_OUT')
      mutate(NO_AUTH, { revalidate: false })
    })

    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.SIGN_IN)
    }
  }, [mutate])
  const status = auth.isAuthenticated ? 'authenticated' : 'unauthenticated'

  const isAuthenticated = auth.isAuthenticated
  const ownerAddress = auth.user?.ownerAddress
  const address = auth.user?.address
  const acknowledgedTerms = !!auth.user?.acknowledgedTerms

  // track session changes on Spindl
  useEffect(() => {
    if (ownerAddress && address && process.env.NEXT_PUBLIC_SPINDL_API_KEY) {
      try {
        spindl.configure({ sdkKey: process.env.NEXT_PUBLIC_SPINDL_API_KEY })
        spindl.track('sp_proxy_address', {}, { address, customerUserId: ownerAddress })
      } catch (err) {
        console.warn('Spindl wallet attribution failed')
      }
    }
  }, [ownerAddress, address])

  const logout = useCallback(async () => {
    // unset auth, do not revalidate (triggers flicker)
    mutate(NO_AUTH, { revalidate: false })

    const response = await fetch('/api/auth/logout')

    if (!response.ok) {
      throw new Error('Failed to logout')
    }

    // TODO: @earthtojake disconnect wallet
  }, [mutate])

  const updateUsername = useCallback(
    async (username: string | null) => {
      if (!auth) {
        throw new Error('Not authenticated')
      }

      if (username) {
        const res = await fetch('/api/auth/username', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username }),
        })

        if (!res.ok) {
          throw new Error('Failed to update username')
        }
      } else {
        const res = await fetch('/api/auth/username', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!res.ok) {
          throw new Error('Failed to remove username')
        }
      }

      await mutate()
    },
    [auth, mutate]
  )

  const acknowledgeTerms = useCallback(async () => {
    const res = await fetch('/api/acknowledge-terms', { method: 'POST' })

    if (!res.ok) {
      throw new Error(await res.text())
    }
  }, [])

  const createMockSessionDONOTUSE = useCallback(
    async (address: Address) => {
      if (!isAdmin) {
        throw new Error('Not signed in as admin')
      }

      const res = await fetch('/api/intern/admin/mock-auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      })

      if (!res.ok) {
        throw new Error('Failed to mock')
      }

      const { auth } = await res.json()

      eventEmitter.emit(EMITTER_EVENTS.SIGN_IN, auth)
    },
    [isAdmin]
  )

  const value = useMemo(() => {
    return {
      ...auth,
      logout,
      mutate,
      refresh,
      acknowledgeTerms,
      updateUsername,
      createMockSessionDONOTUSE,
    }
  }, [auth, logout, mutate, refresh, acknowledgeTerms, updateUsername, createMockSessionDONOTUSE])

  return (
    <WagmiProvider config={rainbowkitWagmiConfig} initialState={initialWalletState}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitAuthenticationProvider enabled adapter={authenticationAdapter} status={status}>
          <RainbowKitProvider>
            <AuthContext.Provider value={value}>
              {!isAuthenticated && error ? (
                <AuthErrorModal onClose={logout} />
              ) : isAuthenticated && !acknowledgedTerms ? (
                // SUPER IMPORTANT!! must enforce terms of use modal globally and as top priority
                <TermsOfUseModal />
              ) : null}
              {children}
            </AuthContext.Provider>
          </RainbowKitProvider>
        </RainbowKitAuthenticationProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
