'use client'

import { baseUrl } from '@lyra/web/constants/urls'
import { darkTheme, RainbowKitProvider as NextRainbowKitProvider } from '@rainbow-me/rainbowkit'
import { useMemo } from 'react'
import { useTheme } from 'tamagui'

export default function RainbowKitProvider({ children }: { children: React.ReactNode }) {
  const theme = useTheme()

  const rbTheme = useMemo(() => {
    const rbTheme = darkTheme()

    // fonts
    rbTheme.fonts.body = 'GT Standard'

    // radii
    rbTheme.radii.actionButton = '2px'
    rbTheme.radii.connectButton = '2px'
    rbTheme.radii.modal = '2px'
    rbTheme.radii.modalMobile = '2px'
    rbTheme.radii.menuButton = '2px'

    // colors
    rbTheme.colors.accentColor = '#F97545'
    rbTheme.colors.accentColorForeground = theme.invertedText?.val
    // rbTheme.colors.actionButtonBorder = theme.color.cta
    // rbTheme.colors.actionButtonBorderMobile = theme.color.cta
    // rbTheme.colors.actionButtonSecondaryBackground = theme.color.cta
    rbTheme.colors.closeButton = theme.secondaryText?.val
    // rbTheme.colors.closeButtonBackground = theme.color.cta
    // rbTheme.colors.connectionIndicator = theme.color.cta
    // rbTheme.colors.downloadBottomCardBackground = theme.color.cta
    // rbTheme.colors.downloadTopCardBackground = theme.color.cta
    rbTheme.colors.error = theme.redPrimary?.val
    // rbTheme.colors.generalBorder = theme.color.cta
    // rbTheme.colors.generalBorderDim = theme.color.cta
    rbTheme.colors.menuItemBackground = theme.buttonBg?.val
    rbTheme.colors.modalBackdrop = theme.overlay?.val
    rbTheme.colors.modalBackground = theme.appBg?.val
    rbTheme.colors.modalBorder = theme.appBg?.val
    rbTheme.colors.modalText = theme.primaryText?.val
    // rbTheme.colors.modalTextDim = theme.color.cta
    rbTheme.colors.modalTextSecondary = theme.secondaryText?.val
    // rbTheme.colors.selectedOptionBorder = theme.color.cta
    // rbTheme.colors.standby = theme.color.cta

    return rbTheme
  }, [theme])

  return (
    <NextRainbowKitProvider
      theme={rbTheme}
      appInfo={{
        appName: 'Derive',
        learnMoreUrl: baseUrl,
      }}
    >
      {children}
    </NextRainbowKitProvider>
  )
}
