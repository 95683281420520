import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  binanceWallet,
  bybitWallet,
  coinbaseWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  safeWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { cookieStorage, createConfig, createStorage } from 'wagmi'

import { customHttpTransport } from '../utils/rpc'
import { arbitrumChain, baseChain, mainnetChain, optimismChain } from './chains'
import { walletConnectCloudProjectId } from './env'

coinbaseWallet.preference = 'eoaOnly'

export const rainbowkitWalletConnectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet,
        rabbyWallet,
        coinbaseWallet,
        safeWallet,
        ledgerWallet,
        walletConnectWallet,
        rainbowWallet,
        bybitWallet,
        binanceWallet,
        okxWallet,
      ],
    },
  ],
  {
    appName: 'Derive',
    projectId: walletConnectCloudProjectId,
  }
)

export const rainbowkitWagmiConfig = createConfig({
  chains: [mainnetChain, arbitrumChain, baseChain, optimismChain],
  transports: {
    [mainnetChain.id]: customHttpTransport(mainnetChain),
    [arbitrumChain.id]: customHttpTransport(arbitrumChain),
    [optimismChain.id]: customHttpTransport(optimismChain),
    [baseChain.id]: customHttpTransport(baseChain),
  },
  connectors: rainbowkitWalletConnectors,
  ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
})
